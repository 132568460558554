$(document).ready(function ()
{
	$(".phone_box").focusin(function ()
	{
		$(this).children("p").show();
	});
});

//Menu VIP
//############################################################
$(document).ready(function ()
{
	$(".responsive_menu").click(function ()
	{
		$(this).children('div').slideToggle();
		$(this).children('ul').slideToggle();

	});
});


//Menu Register
//############################################################
$(document).ready(function () {
	$("#register_button").click(function () {
		$('#register_button').fadeOut('fast');
		$('#registration_formular').fadeIn('slow');
	});
});

//MAIL
//############################################################
$('#reg_email').blur(function ()
{
	var fieldvalue = this.value,
		$this      = $(this);
	if (fieldvalue.length > 0)
	{
		$.ajax({
			url: FF.baseUrl + 'member/registration_check/?email=' + fieldvalue,
			success: function (data)
			{
				if (data == "409")
				{
					$this.validationEngine('showPrompt', lang_member_index_registration_formular_alert_email_exists, 'load', 'bottomLeft');
				}
				else if (data != "200")
				{
					$this.validationEngine('showPrompt', lang_member_index_registration_formular_alert_email_could_not_checked, 'load', 'bottomLeft');
				}
			}
		});
	}
	else
	{
		$('#reg_email').val(lang_member_index_registration_formular_input_value_email + ' *');
	}
});

jQuery(document).ready(function ()
{
	$("#new_password_form").validationEngine({promptPosition: "bottomLeft"});
	$("#registration_formular").validationEngine({promptPosition: "bottomLeft"});
	$("#login_formular").validationEngine({promptPosition: "bottomLeft"});
	$("#password_formular").validationEngine();
});